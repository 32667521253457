import {
  ComponentPropsWithoutRef,
  forwardRef,
  RefObject,
  useEffect,
  useState,
} from 'react';
import cx from 'classnames';
import { Link } from 'src/components/Inputs';
import { PlaidLogo } from 'src/components-v2/SVGs';
import { Icon } from 'src/components-v2/DataDisplay';
import { Box } from 'src/components-v2/Layout';
import {
  isLink,
  NavDataItems,
  Link as LinkType,
  isCardWIcon,
  CardWIcon,
  SubMenuData,
} from '../types';
import { Logo } from '../assets';
import styles from './Dialog.module.scss';
import styled from 'threads5/styles/styled';
import { default as NextLink } from 'next/link';
import { trackingActions, useTrack } from 'src/contexts';
import BaseIcon from 'threads5/Icon';
import BtnPrimary from 'src/components-v2/Inputs/Button/BtnPrimary';
import BtnSecondary from 'src/components-v2/Inputs/Button/BtnSecondary';
import _debounce from 'lodash/debounce';

const StyledUl = styled('ul')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  margin: '16px 0',
  padding: 0,
  '& > li > ul': {
    marginTop: '8px',
    padding: '8px 0',
    marginBottom: '0',
    marginLeft: '-16px',
    marginRight: '-16px',
    background: 'linear-gradient(48deg, #f2fbff, #effffa 50%, #f0fdf8);',
    '& > li': {
      paddingLeft: '16px',
      paddingRight: '16px',
      '& > ul': {
        background: '#fff !important',
        border: '1px solid #EAEFF0',
        borderRadius: '12px',
        marginBottom: '0',
        marginLeft: '0',
        marginRight: '0',
        gap: '8px',
        '& > li': {
          paddingLeft: 0,
        },
      },
    },
    '& > li:last-child': {
      '& > ul': {
        marginBottom: '8px',
      },
    },
  },
});
const StyledLi = styled('li')({
  listStyle: 'none',
  padding: '0',
});
const StyledNavLink = styled('a')({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '16px',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '24px',
  padding: '8px 16px',
});
const StyledNavButton = styled('button')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '24px',
  textAlign: 'left',
  width: '100%',
  padding: '8px 16px',
});
const StyledIconContainer = styled('span')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '28px',
  height: '28px',
  borderRadius: '4px',
  padding: '4px',
  background: 'linear-gradient(48deg, #CDF5FD 0%, #D8FEF3 50%, #E8FED7 100%)',
});

const NavItem = ({
  item,
  onClick,
}: {
  item: LinkType | CardWIcon;
  onClick?: () => void;
}) => {
  const track = useTrack();
  const { title, href, trackingId, ...rest } = item;

  return (
    <NextLink href={(href.startsWith('//') ? 'https:' : '') + href} passHref>
      <StyledNavLink
        sx={{
          color: 'base/black/1000',
        }}
        onClick={(event) => {
          if (trackingId) {
            track({
              type: trackingActions.BUTTON_CLICKED,
              payload: {
                trackingId: trackingId,
                event,
                currentTarget: event.currentTarget,
              },
            });
          }

          if (typeof onClick === 'function') {
            onClick();
          }
        }}
      >
        {isCardWIcon(rest) && (
          <StyledIconContainer>
            {rest.icon === 'PlaidLogo' ? (
              <Logo sx={{ width: '16px', color: 'base/minted/500' }} />
            ) : (
              <BaseIcon
                sx={{ color: 'base/minted/500' }}
                name={rest.icon}
                size={16}
              />
            )}
          </StyledIconContainer>
        )}
        <Box
          component='span'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            lineHeight: '28px',
          }}
        >
          {title}
          {isCardWIcon(rest) && rest.description ? (
            <Box
              component='span'
              sx={{
                color: (t) => {
                  return t.palette['base/minted/900'];
                },
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '20px',
              }}
            >
              {rest.description}
            </Box>
          ) : null}
        </Box>
      </StyledNavLink>
    </NextLink>
  );
};

const NavSubSection = ({
  item,
  level = 1,
  onClick,
}: {
  item: SubMenuData;
  level?: number;
  onClick?: () => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  // this will basically recursivly close nav items when a link is clicked (so the nav starts "fresh" after a navigation)
  const newOnClick = () => {
    setIsOpen(false);
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  if (item.children && item.children.length > 0) {
    const menuId = `${item.id}-menu`;
    return (
      <>
        <StyledNavButton
          aria-expanded={isOpen ? true : false}
          aria-controls={menuId}
          onClick={() => {
            return setIsOpen((prev) => {
              return !prev;
            });
          }}
          sx={{
            color:
              level === 2 && isOpen ? 'base/minted/600' : 'base/black/1000',
          }}
        >
          {item.title}
          <BaseIcon
            sx={{
              transform: isOpen ? 'rotate(-180deg)' : 'rotate(0deg)',
              transition: 'transform 0.3 ease',
              ...(level === 2 && isOpen && { color: 'base/minted/600' }),
            }}
            name='ChevronDown'
            size={16}
          />
        </StyledNavButton>
        <StyledUl
          id={menuId}
          sx={{
            display: isOpen ? 'flex' : 'none',
          }}
        >
          {item.children?.map((subItem, i) => {
            if (isLink(subItem)) {
              return (
                <StyledLi key={`${subItem.href}-${i}`}>
                  <NavItem item={subItem} onClick={newOnClick} />
                </StyledLi>
              );
            }
            return (
              <StyledLi key={`${item.id}-submenu-${i}`}>
                <NavSubSection
                  item={subItem}
                  onClick={newOnClick}
                  level={(level ?? 1) + 1}
                />
              </StyledLi>
            );
          })}
        </StyledUl>
      </>
    );
  }
  return null;
};

type DialogProps = ComponentPropsWithoutRef<'dialog'> & {
  className?: string;
  items: NavDataItems;
  trackingIds: { getAPIkeys: string };
  onClose?: () => void;
};

export const Dialog = forwardRef(
  (props: DialogProps, ref: RefObject<HTMLDialogElement>) => {
    const { className, items, trackingIds, onClose } = props;

    const handleClose = () => {
      onClose?.();
    };

    useEffect(() => {
      if (typeof window === 'undefined') return;

      const handleResize = _debounce(() => {
        if (ref?.current?.open) {
          if (window.innerWidth > 1077) {
            handleClose();
          }
        }
      }, 100);

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    return (
      <dialog
        className={cx(styles.Dialog, className)}
        id='main-menu-dialog'
        ref={ref}
      >
        <div className={styles.Dialog__inner}>
          <div className={styles.Dialog__head}>
            <form method='dialog'>
              <button
                type='submit'
                className={styles.Close}
                tabIndex={0}
                aria-label='Close main menu modal'
                onClick={handleClose}
              >
                <Icon icon='Close' sx={{ width: 22, height: 24 }} />
              </button>
            </form>
            <Link href='/'>
              <a className={styles.Logo} aria-label='Go to Plaid homepage'>
                <PlaidLogo
                  sx={{
                    display: 'block',
                    height: { xs: '24px', sm: '28px' },
                    marginLeft: '16px',
                  }}
                />
              </a>
            </Link>
          </div>
          <div className={styles.Dialog__body}>
            <Box
              sx={{
                flexGrow: 2,
              }}
            >
              <StyledUl>
                {items.map((item, i) => {
                  if (isLink(item)) {
                    return (
                      <StyledLi key={item.id}>
                        <NavItem item={item} onClick={onClose} />
                      </StyledLi>
                    );
                  }
                  return (
                    <StyledLi key={item.id}>
                      <NavSubSection item={item} onClick={onClose} />
                    </StyledLi>
                  );
                })}
              </StyledUl>
            </Box>
            <Box
              sx={{
                padding: '16px 0',
                borderTop: '1px solid #EAEFF0',
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'stretch',
                gap: '12px',
              }}
            >
              <BtnPrimary
                href='https://dashboard.plaid.com/signin/'
                sx={{ justifyContent: 'center' }}
              >
                Log in
              </BtnPrimary>
              <BtnSecondary
                href='https://dashboard.plaid.com/contact'
                trackingId={trackingIds?.getAPIkeys}
                sx={{ justifyContent: 'center' }}
              >
                Contact sales
              </BtnSecondary>
            </Box>
          </div>
        </div>
      </dialog>
    );
  },
);
