import { FC, Fragment, useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import { Typography } from 'src/components-v2/DataDisplay';

import { Card } from '../Card';

import { isCardWImage, Tabs as ItemsProps } from '../types';
import { NAV_LIST_DROPDOWN_HIDE_DELAY } from '../consts';

import styles from './Tabs.module.scss';

type TabsProps = {
  className?: string;
  visible?: boolean;
  items: ItemsProps['children'];
};

export const Tabs: FC<TabsProps> = ({ className, items, visible }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleSetActiveIndex = (i: number) => {
    return (event) => {
      setActiveIndex(i);
    };
  };

  const setDefaultTab = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      setActiveIndex(0);
    }, NAV_LIST_DROPDOWN_HIDE_DELAY);
  };

  useEffect(() => {
    if (!visible) {
      setDefaultTab();
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [visible]);

  return (
    <div className={cx(styles.Tabs, className)}>
      {items.map(({ id, title, children }, i) => {
        const isActive = i === activeIndex;
        const isLargeGrid = children.some((child) => {
          return isCardWImage(child);
        });

        return (
          <Fragment key={`main-menu-tabs-item-${id}`}>
            <div className={styles.Tabs__tab}>
              <button
                type='button'
                tabIndex={-1}
                className={cx(styles.Button, {
                  [styles.Button_active]: isActive,
                })}
                onMouseEnter={handleSetActiveIndex(i)}
                onClick={handleSetActiveIndex(i)}
              >
                <Typography
                  theme='main-menu-new'
                  component='span'
                  variant='p'
                  sx={{
                    mb: 0,
                  }}
                >
                  {title}
                </Typography>
              </button>
            </div>

            <ul
              className={cx(styles.Tabs__list, {
                [styles.Tabs__list_active]: isActive,
                [styles.Tabs__list_large]: isLargeGrid,
              })}
            >
              {children.map((child, j) => {
                const isOverview = 'isOverview' in child && child.isOverview;

                return (
                  <li
                    key={`main-menu-tab-list-item-${id}-${j}`}
                    className={cx(styles.Tabs__item, {
                      [styles.Tabs__item_large]: isOverview,
                    })}
                  >
                    <Card
                      className={styles.Card}
                      {...child}
                      iconBoxSize={40}
                      tabIndex={-1}
                      data-hidden={!isActive}
                    />
                  </li>
                );
              })}
            </ul>
          </Fragment>
        );
      })}
    </div>
  );
};
