import {
  Children,
  FC,
  useEffect,
  useRef,
  useState,
  MouseEvent,
  KeyboardEvent,
  FocusEvent,
  useCallback,
  CSSProperties,
} from 'react';
import { animated, useSpring, useSprings } from '@react-spring/web';
import cx from 'classnames';

import { Typography } from 'src/components-v2/DataDisplay';
import { Link } from 'src/components/Inputs';
import { useOutsideClick } from 'src/hooks';
import { easeInOut } from 'src/lib/config/ease.config';

import { Dropdown } from '../Dropdown';
import { Tabs } from '../Tabs';
import { Columns } from '../Columns';
import { List } from '../List';

import { isLink, NavDataItems } from '../types';

import styles from './NavList.module.scss';

type NavListProps = {
  items: NavDataItems;
};

export const NavList: FC<NavListProps> = ({ children, items }) => {
  // COMMON
  const childrenCount = Children.count(children);
  const dropdownRefs = useRef<Array<HTMLDivElement>>(
    new Array(items.length + childrenCount),
  );
  const listItemsRefs = useRef<Array<HTMLLIElement>>(
    new Array(items.length + childrenCount),
  );

  const ddRefCb = (ref: HTMLDivElement) => {
    if (!ref) return;

    // const index = Array.from(ref.parentNode.children).indexOf(ref);
    const index = items.findIndex((item) => {
      return item.id === ref.getAttribute('id');
    });

    if (dropdownRefs.current && index !== -1) {
      dropdownRefs.current[childrenCount + index] = ref;
    }
  };

  const liRefCb = (ref: HTMLLIElement) => {
    if (!ref) return;

    const index = Array.from(ref.parentNode.children).indexOf(ref);

    listItemsRefs.current[index] = ref;
  };

  const prevIndex = useRef(-1);
  const [activeIndex, setActiveIndex] = useState(-1);

  const focusTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const isFocusTimeout = useRef(false);

  const AnimatedDropdown = animated(Dropdown);

  // SPRINGS
  const [dropdownSprings] = useSprings<CSSProperties>(
    items.length,
    (i) => {
      const isActive = i === activeIndex;

      return {
        opacity: isActive ? '1' : '0',
        pointerEvents: isActive ? 'all' : 'none',
        immediate: false,
        delay:
          isActive && prevIndex.current !== -1 && activeIndex !== -1
            ? 100
            : undefined,
        config: {
          duration: 100,
          easing: easeInOut,
        },
      };
    },
    [activeIndex],
  );

  const [layerSpring] = useSpring<CSSProperties>(() => {
    const dropdown = dropdownRefs.current[activeIndex];
    const dropdownType = dropdown?.getAttribute('data-type');
    const leftPositionDelta =
      dropdownType === 'tabs' ? 42 : dropdownType === 'columns' ? 22 : 8;
    const item = listItemsRefs.current[activeIndex];
    const commonProps = {
      immediate: false,
      config: (key) => {
        if (
          (key === 'width' || key === 'height' || key === 'left') &&
          prevIndex.current === -1
        ) {
          return {
            duration: 0,
          };
        }

        return {
          duration: 200,
          easing: easeInOut,
        };
      },
    };

    if (activeIndex === -1)
      return {
        translateY: '-8px',
        opacity: '0',
        pointerEvents: 'none',
        ...commonProps,
      };

    return {
      width: `${dropdown.offsetWidth + 16}px`,
      height: `${dropdown.offsetHeight + 24}px`,
      left: `${item.offsetLeft - leftPositionDelta}px`,
      translateY: '0px',
      opacity: '1',
      pointerEvents: 'all',
      ...commonProps,
    };
  }, [activeIndex]);

  // MOUSE EVENTS
  const handleMouseEnter = (event: MouseEvent<HTMLElement>) => {
    if (isFocusTimeout.current) return;

    const nextIndex = Array.from(
      event.currentTarget.parentNode.parentNode.children,
    ).indexOf(event.currentTarget.parentElement);

    setActiveIndex((prevValue) => {
      prevIndex.current = prevValue;
      return nextIndex;
    });
  };

  const handleMouseLeaveFromMenu = () => {
    if (isFocusTimeout.current) return;

    setActiveIndex((prevValue) => {
      prevIndex.current = prevValue;
      return -1;
    });
  };

  const handleOutsideClick = () => {
    if (activeIndex !== -1) {
      (document.activeElement as HTMLElement).blur();

      setActiveIndex((prevValue) => {
        prevIndex.current = prevValue;
        return -1;
      });
    }
  };

  const rootRef = useOutsideClick<HTMLDivElement>(handleOutsideClick);

  // FOCUS
  const handleFocus = (event: FocusEvent) => {
    const target = event.target as HTMLElement;

    const nextIndex = Array.from(target.parentNode.parentNode.children).indexOf(
      target.parentElement,
    );

    const nextDropdown = dropdownRefs.current[nextIndex];
    const focusableLinks = Array.from(
      nextDropdown.querySelectorAll('a:not([data-hidden="true"])'),
    );
    (focusableLinks[0] as HTMLElement).focus();

    setActiveIndex((prevValue) => {
      prevIndex.current = prevValue;
      return nextIndex;
    });
  };

  // KEYBOARD
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
      event.stopPropagation();
    } else if (event.key === 'Tab') {
      event.preventDefault();

      if (focusTimeoutRef.current) {
        clearTimeout(focusTimeoutRef.current);
      }

      isFocusTimeout.current = true;

      focusTimeoutRef.current = setTimeout(() => {
        isFocusTimeout.current = false;
      }, 1000);

      const nextIndex = activeIndex + (event.shiftKey ? -1 : 1);
      const nextElement =
        dropdownRefs.current[nextIndex] || listItemsRefs.current[nextIndex];

      if (!nextElement) {
        setActiveIndex((prevValue) => {
          prevIndex.current = prevValue;
          return -1;
        });

        return;
      }

      const focusableLinks = Array.from(
        nextElement.querySelectorAll('a:not([data-hidden="true"])'),
      );
      (focusableLinks[0] as HTMLElement).focus();

      setActiveIndex((prevValue) => {
        prevIndex.current = prevValue;
        return dropdownRefs.current[nextIndex] ? nextIndex : -1;
      });
    }
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    const currentDropdown = dropdownRefs.current[activeIndex];

    if (!currentDropdown) return;

    const { key } = event;

    const focusableElements = Array.from(
      currentDropdown.querySelectorAll('a:not([data-hidden="true"]), button'),
    );
    const currentIndex = focusableElements.indexOf(document.activeElement);

    if (key === 'ArrowUp') {
      (
        focusableElements[
          currentIndex === 0 ? focusableElements.length - 1 : currentIndex - 1
        ] as HTMLElement
      ).focus();
    } else if (key === 'ArrowDown') {
      (
        focusableElements[
          currentIndex === focusableElements.length - 1 ? 0 : currentIndex + 1
        ] as HTMLElement
      ).focus();
    }
  };

  const handleDocumentKeyUp = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        if (activeIndex !== -1) {
          (document.activeElement as HTMLElement).blur();

          setActiveIndex((prevValue) => {
            prevIndex.current = prevValue;
            return -1;
          });
        }
      }
    },
    [activeIndex],
  );

  useEffect(() => {
    document.addEventListener('keyup', handleDocumentKeyUp);

    return () => {
      document.removeEventListener('keyup', handleDocumentKeyUp);
    };
  }, [handleDocumentKeyUp]);

  return (
    <div
      ref={rootRef}
      className={styles.NavList}
      onMouseLeave={handleMouseLeaveFromMenu}
    >
      <ul className={styles.NavList__inner}>
        {Children.map(children, (child, i) => {
          return (
            <li
              key={`main-menu-nav-prefix-item-${i}`}
              ref={liRefCb}
              className={styles.NavList__item}
            >
              {child}
            </li>
          );
        })}

        {items.map((item, listIndex) => {
          const isActive = activeIndex === listIndex + childrenCount;

          return (
            <li
              ref={liRefCb}
              key={item.id}
              className={
                styles[`NavList__${'type' in item ? item.type : 'item'}`]
              }
            >
              {isLink(item) ? (
                <Link href={item.href} trackingId={item.trackingId}>
                  <a
                    className={styles.Link}
                    role='link'
                    tabIndex={0}
                    onMouseEnter={handleMouseLeaveFromMenu}
                  >
                    <Typography
                      sx={{ whiteSpace: 'nowrap', mb: 0 }}
                      theme='main-menu-new'
                      variant='p'
                      component='span'
                    >
                      {item.title}
                    </Typography>
                  </a>
                </Link>
              ) : (
                // eslint-disable-next-line react/button-has-type
                <button
                  className={styles.Button}
                  aria-haspopup={true}
                  aria-expanded={isActive}
                  aria-controls={item.id}
                  tabIndex={0}
                  onMouseEnter={handleMouseEnter}
                  onFocus={handleFocus}
                >
                  <Typography
                    sx={{ whiteSpace: 'nowrap', mb: 0 }}
                    theme='main-menu-new'
                    variant='p'
                    component='span'
                  >
                    {item.title}
                  </Typography>
                </button>
              )}
            </li>
          );
        })}
      </ul>

      <animated.div
        className={styles.AnimatedLayer}
        style={layerSpring}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
      >
        <div className={styles.AnimatedLayer__inner}>
          <div className={styles.AnimatedLayer__body}>
            {items.map((item, listIndex) => {
              if (isLink(item)) return null;

              const { type } = item;
              const index = listIndex + childrenCount;
              const isActive = activeIndex === index;
              const style = dropdownSprings[index];

              return (
                <AnimatedDropdown
                  key={item.id}
                  ref={ddRefCb}
                  className={cx(styles.Dropdown, styles[`Dropdown_${type}`])}
                  id={item.id}
                  style={style}
                  data-type={type}
                >
                  {type === 'list' && <List items={item.children} />}
                  {type === 'columns' && <Columns items={item.children} />}
                  {type === 'tabs' && (
                    <Tabs items={item.children} visible={isActive} />
                  )}
                </AnimatedDropdown>
              );
            })}
          </div>
        </div>
      </animated.div>
    </div>
  );
};
