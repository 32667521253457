import { FC, HTMLAttributes } from 'react';
import cx from 'classnames';
import BaseIcon from 'threads5/Icon';

import { Typography } from 'src/components-v2/DataDisplay';
import { Link } from 'src/components-v2/Inputs';

import { isCardWIcon, isCardWImage, CardWIcon, CardWImage } from '../types';
import { ChevronRightBold, Logo } from '../assets';
import { Image } from 'src/components-v2/Media';

import styles from './Card.module.scss';

type CardProps = HTMLAttributes<HTMLAnchorElement> & {
  iconSize?: 18 | 20;
  iconBoxSize?: 32 | 40;
} & (CardWIcon | CardWImage);

export const Card: FC<CardProps> = (props) => {
  const {
    className,
    href,
    title,
    description,
    iconSize = 18,
    iconBoxSize = 32,
    ...rest
  } = props;

  const Chevron = description ? (
    <ChevronRightBold sx={{ width: 14 }} />
  ) : (
    <BaseIcon name='ChevronRight' size={16} />
  );

  const textPart = (
    <>
      <span className={styles.Card__title}>
        <Typography
          theme='main-menu-new'
          variant='p'
          component='span'
          sx={{ mb: 0 }}
        >
          {title}
        </Typography>

        {Chevron}
      </span>

      {description && (
        <Typography
          className={styles.Card__description}
          theme='main-menu-new'
          variant='p'
          component='span'
        >
          {description}
        </Typography>
      )}
    </>
  );

  if (isCardWImage(props)) {
    const ProductShot = {
      link: (
        <Image
          src='https://images.ctfassets.net/ss5kfr270og3/5rJPK58W3Dz1FWsA2vOT1e/1becbed98efe1e1413607110b17596fc/Link.png?fm=webp&q=70&w=326'
          srcset='https://images.ctfassets.net/ss5kfr270og3/5rJPK58W3Dz1FWsA2vOT1e/1becbed98efe1e1413607110b17596fc/Link.png?fm=webp&q=70&w=326 1x,
          https://images.ctfassets.net/ss5kfr270og3/5rJPK58W3Dz1FWsA2vOT1e/1becbed98efe1e1413607110b17596fc/Link.png?fm=webp&q=70&w=652 2x'
          width={632}
          height={884}
          alt='Link'
          sx={{ marginTop: '-154px' }}
        />
      ),
      layer: (
        <Image
          src='https://images.ctfassets.net/ss5kfr270og3/0Akzu34Dw2ub564y1wrfA/90ed336cce5f19026a31408458d242c0/Layer.png?fm=webp&q=70&w=326'
          srcset='https://images.ctfassets.net/ss5kfr270og3/0Akzu34Dw2ub564y1wrfA/90ed336cce5f19026a31408458d242c0/Layer.png?fm=webp&q=70&w=326 1x,
          https://images.ctfassets.net/ss5kfr270og3/0Akzu34Dw2ub564y1wrfA/90ed336cce5f19026a31408458d242c0/Layer.png?fm=webp&q=70&w=652 2x'
          width={632}
          height={884}
          alt='Layer'
          sx={{ marginTop: '-70px' }}
        />
      ),
    };

    return (
      <Link href={href}>
        <a
          className={cx(
            styles.Card,
            styles.Card_image,
            { [styles.Card_description]: !!description },
            className,
          )}
          {...rest}
        >
          <span
            className={cx(
              styles.Card__image,
              styles[`Card__image_${props.imagePos}`],
            )}
          >
            <span className={styles.Image}>{ProductShot[props.image]}</span>
          </span>

          <span className={styles.Card__body}>{textPart}</span>
        </a>
      </Link>
    );
  }

  if (isCardWIcon(props)) {
    const Icon =
      props.icon === 'PlaidLogo' ? (
        <Logo sx={{ width: `${iconSize}px` }} />
      ) : (
        <BaseIcon
          clipPathId={`${href}-clip-${Date.now()}`}
          data-name={props.icon}
          name={props.icon}
          size={iconSize}
          sx={{ color: 'inherit' }}
        />
      );

    return (
      <Link href={href}>
        <a
          className={cx(
            styles.Card,
            styles.Card_icon,
            { [styles.Card_description]: !!description },
            className,
          )}
          {...rest}
        >
          <span
            className={cx(
              styles.Card__icon,
              styles[`Card__icon_${iconBoxSize}`],
            )}
          >
            {Icon}
          </span>

          <span className={styles.Card__body}>{textPart}</span>
        </a>
      </Link>
    );
  }

  return (
    <Link href={href}>
      <a className={cx(styles.Card, className)} {...rest}>
        {textPart}
      </a>
    </Link>
  );
};
