import {
  ComponentPropsWithoutRef,
  forwardRef,
  PropsWithChildren,
  RefObject,
} from 'react';
import cx from 'classnames';

import styles from './Dropdown.module.scss';

type DropdownProps = PropsWithChildren<
  ComponentPropsWithoutRef<'div'> & {
    className?: string;
  }
>;

export const Dropdown = forwardRef(
  (props: DropdownProps, ref: RefObject<HTMLDivElement>) => {
    const { children, className, ...rest } = props;

    return (
      <div ref={ref} className={cx(styles.Dropdown, className)} {...rest}>
        {/* <div className={styles.Dropdown__inner}>{children}</div> */}
        {children}
      </div>
    );
  },
);
